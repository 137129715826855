<script>
import Tag from '@/components/card-view/tag.vue'
import UserInfo from '@/components/detail-view/user-info.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import AddWx from '@/components/detail-view/add-wx.vue'
import Detail from '@/modules/clue/component/detail.vue'
import UpdateVue from '@/modules/appointment/update.vue'
import { driveUpdatePost,appointmentReviewPost } from '@/services/appointment'
import { dateFormat } from '@/utils'
export default {
  components:{ UserInfo,qwIcon,Tag,AddWx,Detail,UpdateVue },
  data() {
    return {
      id: '',
      clueId:'',
      isShowReview: false,
      reviewRemark: '',
      showScheduling: false,
      appointmentInfo: {}
    }
  },
  computed:{
    getUserInfo() {
      console.log('userInfo:',this.$store.getters.userInfo)
      return this.$store.getters.userInfo
    },
  },
  created() {
    const { id, clueId } = this.$route.query
    if (id && clueId ){
      this.id = id
      this.clueId = clueId
    }
  },
  methods: {
    goFollow(followResult, followMode='') {
      this.$router.push({
        path: '/clue-follow',
        query: {
          id: this.clueId,
          appointmentId: this.id,
          followResult, // '1011005', // 1011005取消预约  1011006修改预约
          followMode, // 1010003 店内接待
        }
      })
    },
    getNone(data) {
      let appid = 'wxdafaf0480578a1b3'
      const { vehicleCode: modelCode, vinCode, typeCode, id:appointId, leadId, customerMobile:appointmentPhone, testDriveActivityId } = data.appointments[0]
      if (process.env.VUE_APP_ENV !== 'uat') appid = 'wx25e0b11c7aaf8139'
      if (!appid) return false
      window.wx.invoke('launchMiniprogram', {
        'appid': appid,
        'path': `pages/index/index?modelCode=${modelCode}&vinCode=${vinCode}&type=${typeCode}&appointId=${appointId}&leadId=${leadId}&appointmentPhone=${appointmentPhone}&appointmentActivityId=${testDriveActivityId}&fromAppointment=true&dealer=${this.$store.state.app.selectedShopId}`
      }, (res) => {
        // console.log(res)
        if (res.err_msg === 'launchMiniprogram:ok'){
          console.log('跳转成功')
        } else {
          this.$toast.fail(this.$t('无法跳转'))
        }
      })
    },
    // 上门试驾 排程
    goSchedule(clueDetail){
      const item = clueDetail.appointments[0]
      this.$set(this,'appointmentInfo',item)
      this.showScheduling = true
    },
    //  确认排程
    async confirmScheduling(data) {
      console.log('data:',data)
      data.appointmentStartTime = dateFormat(data.appointmentStartTime, 'YYYY-MM-DD HH:mm:ss')
      data.appointmentEndTime = dateFormat(data.appointmentEndTime, 'YYYY-MM-DD HH:mm:ss')
      data.modelCode = data.vehicleCode
      // 直接排程
      data.id = this.appointmentInfo.id
      await driveUpdatePost(data)
      this.showScheduling = false
      window.location.reload()
    },
    openReview(){
      this.reviewRemark = ''
      this.isShowReview = true
    },
    // 审核驳回 1：驳回、2：通过
    async onReview(action, done){
      if (action==='overlay')done()
      if (action==='cancel'&&!this.reviewRemark.trim()) {
        this.$toast(this.$t('请输入审核意见'))
        done(false)
        return
      }
      if (['confirm','cancel'].indexOf(action)>-1){
        const params = {
          reviewStatus: action==='cancel'?1:2,
          reviewRemark: this.reviewRemark,
          id: this.id
        }
        const res = await appointmentReviewPost(params)
        done()
        window.location.reload()

      }
    }
  }
}
</script>
<template>
  <div v-if="id" class="page">
   <Detail :id="clueId" :appointmentId="id">
    <template v-if="$store.getters.userInfo.type == 1" v-slot:btns="{data}">
      <template v-if="(getUserInfo.authorizeIds.includes(data?.followUserId) || getUserInfo.id == data.authorizedEmployeeId) && getUserInfo?.roleLogos.includes(data.followUserRoleCode) && data.followUserId">
        <!-- <div v-if="['CONFIRMED','TIMEOUT_CONFIRMED'].includes(data.appointmentStatusCode)" class="btns">
         <van-button v-btn-premission="'BTN-TEST-CANCEL'" round class="btn" type="default" @click="goFollow('1011005', '1010001')">{{ $t('取消预约') }}</van-button>
         <van-button v-btn-premission="'BTN-TEST-EDIT'" round class="btn" type="default" @click="goFollow('1011006', '1010001')">{{ $t('修改预约') }}</van-button>
         <van-button v-btn-premission="'BTN-TEST-START'"  round class="btn" type="primary" @click="getNone(data)">{{ $t('开始试驾') }}</van-button>
        </div> -->
        <div v-if="['NO_ARRIVAL','TIMEOUT_NO_ARRIVAL'].includes(data.appointmentStatusCode)" class="btns">
         <van-button round class="btn" type="default" @click="goFollow('1011005', '1010001')">{{ $t('取消预约') }}</van-button>
         <van-button  round class="btn" type="default" @click="goFollow('1011006', '1010001')">{{ $t('修改预约') }}</van-button>
         <van-button  round class="btn" type="primary" @click="goFollow('1011002','1010003')">{{ $t('到店接待') }}</van-button>
        </div>
        <!-- 上门试驾的逻辑 -->
        <!-- <template v-if="['CONFIRMED','TIMEOUT_CONFIRMED','TO_BE_CONFIRM'].includes(data.appointmentStatusCode)">
          <div v-if="data.appointments[0].typeCode === '2002002'&& data.appointments[0].status !== 0" class="btns">
            店长角色并且预约单状态是待审核
            <div v-if="getUserInfo?.roleLogos.includes('1014003')&&data.appointments[0].reviewStatus==='0'&&data.appointments[0].tableType!=='TIMEOUT_CANCEL'" class="btns">
              <van-button round class="btn" type="primary" @click="openReview">{{ $t('审核') }}</van-button>
            </div>
            <div v-if="data.appointments[0].reviewStatus!=='0'" class="btns">
              <van-button v-btn-premission="'BTN-TEST-CANCEL'" round class="btn" type="default" @click="goFollow('1011005', '1010001')">{{ $t('取消预约') }}</van-button>
              审核被驳回时
              <van-button v-if="!(data.appointments[0].reviewStatus==='2'&&data.appointments[0].status!==2)" v-btn-premission="'BTN-TEST-EDIT'" round class="btn" type="default" @click="goFollow('1011006', '1010001')">{{ $t('修改预约') }}</van-button>
              排程
              <van-button v-if="data.appointments[0].reviewStatus==='2'&&data.appointments[0].status!==2" round class="btn" type="default" @click="goSchedule(data)">{{ $t('预约排程') }}</van-button>
              开始试驾
              <van-button v-if="data.appointments[0].reviewStatus==='2'&&data.appointments[0].status===2" v-btn-premission="'BTN-TEST-START'"  round class="btn" type="primary" @click="getNone(data)">{{ $t('开始试驾') }}</van-button>
            </div>
          </div>
        </template> -->
      </template>
    </template>
  </Detail>
  <!-- 审核弹窗 -->
  <van-dialog v-model="isShowReview" title="审核意见" 
      show-cancel-button 
      :cancel-button-text="$t('驳回')" 
      :confirm-button-text="$t('通过')"
      :close-on-click-overlay="true"
      :before-close="onReview">
      <van-field
        v-model="reviewRemark"
        rows="2"
        autosize
        label=""
        type="textarea"
        maxlength="50"
        :placeholder="$t('请输入审核意见')"
        show-word-limit
      />
    </van-dialog>
  <!-- 排程信息 -->
  <van-popup v-model="showScheduling" style="width:100%; max-width: 500px;height:100%">
    <UpdateVue v-if="showScheduling" :data="appointmentInfo" @confirm="confirmScheduling" @close="showScheduling=false"></UpdateVue>
  </van-popup>
  </div>
</template>
<style lang="less" scoped>
.page{
  padding: 16px 16px 96px;
  // position: relative;
  // height: 100vh;
  // overflow-y: auto;
  // box-sizing: border-box;
  /deep/ .btns{
    padding: 16px 16px 36px;
    display: flex;
    justify-content: space-around;  
    position: fixed;
    bottom:0;
    left: 50%;
    z-index: 999;
    transform: translate(-50%);
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    .btn{
      width: 32%;
    }
  }
}

</style>
